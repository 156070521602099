export const usePatentFamilies = () => {
  const { axios, onError } = useApi()
  return useQuery({
    queryFn: async () => {
      const response = await axios.get<ApiList<PatentFamily>>(
        'patent-families',
        { params: { counts: ['patents'], relations: ['patents'] } }
      )
      return response.data
    },
    onError,
    queryKey: ['patent_families']
  })
}
